<template>
  <div>
    <div class="tableBlock">
      <!-- 参会人员管理表格 -->
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 24px"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <!-- 参会人 -->
        <el-table-column :label="$t('Participant')" width="320">
          <div slot-scope="scope" class="tit">
            <img :src="scope.row.avatar ? scope.row.avatar : DEFAULT_AVATAR" />
            <div>
              <span
                class="use_name textOverflow"
                :title="
                  scope.row.name_en | priorFormat(scope.row.name_zh, LOCALE)
                "
                >{{
                  scope.row.name_en | priorFormat(scope.row.name_zh, LOCALE)
                }}</span
              >
              <span
                class="textOverflow"
                :title="
                  scope.row.job_title_en
                    | priorFormat(scope.row.job_title_zh, LOCALE)
                "
                >{{
                  scope.row.job_title_en
                    | priorFormat(scope.row.job_title_zh, LOCALE)
                }}</span
              >
            </div>
          </div>
        </el-table-column>
        <!-- 账号类型 -->
        <el-table-column :label="$t('count_Type')">
          <span slot-scope="scope">{{
            scope.row.is_admin == 1 ? $t("Admin") : $t("General")
          }}</span>
        </el-table-column>
        <!-- 报名状态 -->
        <el-table-column :label="$t('Status')">
          <template slot-scope="scope">
            <div class="states_box">
              <div v-if="scope.row.audit_status == 0">
                <div style="background: #bfbfbf"></div>
                <span>{{ $t("Pending") }}</span>
              </div>
              <div v-if="scope.row.audit_status == 1">
                <div style="background: #52c41a"></div>
                <span>{{ $t("RegisterSuccessfully") }}</span>
              </div>
              <div v-if="scope.row.audit_status == 2">
                <div style="background: #f5222d"></div>
                <span>{{ $t("RegisterFailed") }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 报名时间 -->
        <el-table-column :label="$t('RegisterTime')">
          <template slot-scope="scope">
            <span>{{ scope.row.created_time | secondFormat("LLL") }}</span>
          </template>
        </el-table-column>
        <!-- 坐席类型 -->
        <!-- 【航博会】_控制台_会议管理_参会人管理_页面修改 去掉座席列 -->
        <template v-if="PJname != 'overseasMeeting'&&PJSource!=21">
          <el-table-column prop="apply_type" :label="$t('SeatType')">
            <template slot-scope="scope">
              <span v-if="scope.row.booth_user_type == 1&&(scope.row.apply_type==1||scope.row.apply_type==2)">
                {{ $t("OnlineSeat") }}
              </span>
              <span v-else-if="scope.row.booth_user_type == 2&&(scope.row.apply_type==1||scope.row.apply_type==2)">
                {{ $t("OfflineSeat") }}
              </span>
              <span v-else>-</span
              >
            </template>
          </el-table-column>
        </template>
        <!-- 【航博会】_控制台_会议管理_参会人管理_页面修改 设置会刊联系人 -->
        <template v-if="PJSource==21" >
            <el-table-column prop="apply_type" :label="$t('set_hk_user')">
                <template slot-scope="scope">
                    <el-switch
                      @change="HK_user_Change(scope.row)"
                      :disabled="!me_isAdmin||(scope.row.is_catalogue==0&&scope.row.catalogue_nums==4)"
                      v-model="scope.row.is_catalogue"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#EAECF0">
                    </el-switch>
                </template>
            </el-table-column>
        </template>
        <!-- 操作 -->
        <el-table-column :label="$t('Edit')" width="200">
          <template slot-scope="scope">
            <!-- 进入坐席 (航博会没有进入坐席) -->
            <el-button
              v-if="scope.row.live_id && scope.row.user_id == USER_ID&&(scope.row.apply_type==1||scope.row.apply_type==2)&&PJSource!=21"
              @click="handleIn(scope.row)"
              type="text"
              size="small"
              >{{ $t("EnterOnlineBooth") }}</el-button
            >
            <!-- <el-divider v-if="scope.row.seats==1 && scope.row.apply_type!=4" direction="vertical"></el-divider> -->
            <!-- 洽谈记录-->
            <el-button
              v-if="
                (me_isAdmin || scope.row.user_id == USER_ID) &&
                scope.row.live_id&&(scope.row.apply_type==1||scope.row.apply_type==2)&&PJSource!==21
              "
              @click="handleRecord(scope.row)"
              type="text"
              size="small"
              >{{ $t("Meeting_List") }}</el-button
            >
            <!-- 设置管理员  -->
            <el-button
              v-if="
                me_isAdmin &&
                scope.row.is_admin == 0 &&
                inspectButton('mem:jmp:sea')
              "
              @click="handleSet(scope.row)"
              type="text"
              size="small"
              >{{ $t("Become Admin") }}</el-button
            >
            <!-- <el-divider v-if="me_isAdmin&&scope.row.is_admin==0&&inspectButton('mem:jmp:sea') &&scope.row.apply_type!=4&&inspectButton('mem:jmp:sea')" direction="vertical"></el-divider> -->
            <!-- 解绑 -->
            <el-button
              v-if="
                (inspectButton('mem:jmp:clb') &&
                  scope.row.user_id == USER_ID) ||
                (me_isAdmin && inspectButton('mem:jmp:clb'))
              "
              type="text"
              size="small"
              @click="handleUnbind(scope.row)"
              >{{ $t("Unbind") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { handleToVideoChatPage } from "~/baseUtils";
import templatePage from '../../../../../baseComponents/baseBooth/templatePage.vue';
export default {
  components: { templatePage },
  name: "conference-attendance",
  data() {
    return {
      user_id: "",
      params_userID: "",
      title: "",
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      me_isAdmin: false,
      PJname: "",
    };
  },
  mounted() {
    console.log(this.PJSource);
    this.PJname = process.env.VUE_APP_PROJECT_NAME;
    this.user_id = this.$store.state.baseStore.userId;
    this.getList();
  },
  methods: {
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async HK_user_Change(row){
      console.log('----------',row);
      console.log('----------',row.is_catalogue);
      let params={
        meeting_user_apply_id:row.meeting_user_apply_id,
        is_catalogue:row.is_catalogue
      }
      let result = await this.$store.dispatch("baseConsole/publication_person",params);
      if(result.success){
        this.$message.success(this.$t('SetSuccess'))
        this.getList()
      }
      
    },
    async getList() {
      this.loading = true;
      let params = {
        meeting_id: this.$store.state.meeting_id,
        company_id: this.$store.state.baseStore.userInfo.company_id,
        limit: 10,
        start: this.currentPage - 1,
      };
      let result = await this.$store.dispatch(
        "baseConsole/attendeeManage",
        params
      );
      this.loading = false;
      if (result && result.success) {
        if (result.admin_user_id == this.$store.state.baseStore.userInfo.id) {
          this.me_isAdmin = true;
        } else {
          this.me_isAdmin = false;
        }
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
        this.params_userID = result.admin_user_id;
      }
    },
    //解绑
    async handleUnbind(row) {
      if (row.is_admin == 1) {
        this.$message.warning(this.$t("AdminUbind"));
        return;
      }
      this.$confirm(this.$t("ConfirmUnbind"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let del = await this.$store.dispatch(
            "baseConsole/attendeeManage_del",
            { meeting_user_apply_id: row.meeting_user_apply_id }
          );
          if (del && del.success) {
            this.$message.success(this.$t("UnbindSuccess"));
            await this.$store.dispatch("baseStore/baseSign_getUserInfo", {
              user_id: this.USER_INFO.id,
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
    //进入坐席
    async handleIn(row) {
      this.goToVchatPage(
        row.live_id,
        this.USER_ID,
        "seats",
        true,
        this.MEETING_INFO.start_time,
        this.MEETING_INFO.end_time,
        this.$t("meet_not_start"),
        this.$t("meet_not_end")
      );
    },
    //设置管理员
    handleSet(row) {
      this.$confirm(this.$t("ChangeAdmin "), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          // return
          let params = {
            company_id: this.$store.state.baseStore.userInfo.company_id,
            ori_user_id: this.params_userID,
            des_user_id: row.user_id,
            meeting_id: this.MEETING_ID,
          };
          let set_user = await this.$store.dispatch(
            "baseConsole/attendeeManage_set",
            params
          );
          if (set_user && set_user.success) {
            this.$message.success(this.$t("SetSuccess"));
            await this.$store.dispatch("baseStore/baseSign_getUserInfo", {
              user_id: this.USER_INFO.id,
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
    // 洽谈记录
    handleRecord(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/console/conference/record",
        query: {
          parameter: this._encode({
            booth_type: row.booth_type,
            user_id: row.user_id,
            is_admin: this.me_isAdmin,
          }),
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.line {
  width: 100%;
  height: 1px;
  background: #c0c4cc;
}
.tit {
  display: flex;
  align-items: center;
  img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
  }
  div {
    padding-left: 8px;
    cursor: pointer;
  }
  .use_name {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.85);
    max-width: 250px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  span {
    max-width: 250px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #909399;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.states_box > div {
  display: flex;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    background: #bfbfbf;
    border-radius: 3px;
    display: inline-block;
    margin-right: 8px;
  }
  span {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }
}
</style>